.hero-container {
    background-image: url("../assets/HeroSectionBG.png"); /* Set the background image */
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    /* Remove animation property */
}


.hero-power-icon {
    position: absolute;
    bottom: 5rem;
    font-size: 50px;
    color: #ff8000;
    cursor: pointer;
}

.hero-power-icon:hover {
    background-color: #333333;
}


.hero-container {
     /*
     background: url('/images/img-home.jpg') center center/cover no-repeat;
     */
    position: relative; /* Add relative positioning to the container */
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    object-fit: contain;

    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);

}



.hero-page-title {
    overflow-y: hidden;
    font-size: 75px;
    margin-top: -100px;
    color: #ff8000;
    padding: 10px; /* Adjust the left padding value here */
    border-radius: 6px;
    /*box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);*/
    transition: background-color 0.3s, color 0.3s, box-shadow 0.3s;
    animation: wobble 0.5s ease-in-out infinite;
    /*background-color: #444444; !* Duurzame groene kleur *!*/
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    /* Other styles */


}

/*.hero-container-card-price-indication {
    position: absolute;
    bottom:60px;
    left: 0px;
    margin: 20px;
}
.hero-container-card-the-next-step {
    position: absolute;
    bottom: 60px;
    right: 0px;
    margin: 20px;
}*/


.hero-container > p {
    margin-top: 8px;
    color: whitesmoke;
    padding: 10px 20px;
    font-size: 45px;
    font-weight: bold;
    border-radius: 20px;
    text-align: center;
}
.hero-container a {
    color: #ff8000;
    text-decoration: underline;
    font-weight: bold;
    transition: color 0.3s ease;
}

.hero-container a:hover {
    text-decoration: underline;
    color: darkorange;
}
.hero-btns {
    margin-top: 12px;
}

.hero-btns button{
    margin: 10px;
}

.fa-play-circle {
    margin-left: 4px;
}

.direct-hero-section{
    padding: 20px;
    font-size: 10rem;
}


@media screen and (max-width: 1200px) {
    .hero-container > h1 {
        font-size: 90px;
        margin-top: -150px;
    }
}

@media screen and (max-width: 1000px) {
    .hero-container h1 {
        font-size: 60px;
    }

    .direct-hero-section{
        font-size: 6rem;
    }

    .price-hero-section{
        font-size: 1rem;
    }

    .hero-btns button{
        margin-top: 5px;
    }
}

@media screen and (max-width: 930px) {
    .hero-btns {
        margin-top: 12px;
        width: 100%;

        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .hero-btns button{
        width: 80%;
    }

    .direct-hero-section{
        font-size: 5rem;
    }
}



@media screen and (max-width: 800px) {
    .hero-container > h1 {
        font-size: 20px;
    }

    .direct-hero-section{
        font-size: 4rem;
    }

    .hero-container > p {
        font-size: 20px;
    }

    .btn-mobile {
        display: block;
        text-decoration: none;
    }

    .hero-btns button {
        margin-top: 5px;
        font-size: 1rem;
        max-width: 450px;
    }
}

@media screen and (max-width: 622px) {
    .hero-container > h1 {
        font-size: 30px;
    }

    .direct-hero-section{
        font-size: 3.5rem;
    }
}


