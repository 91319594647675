.news-banner {
    display: flex;
    margin-top: 4rem;
    padding-top: 2rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: #ffc314;
    color: black;
}

.banner-title {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
}

.banner-message {
    font-size: 1.5rem;
    margin-bottom: 1rem;
}
