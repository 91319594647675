.muay-thai-technique {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center content horizontally */
    justify-content: center; /* Center content vertically */
    text-align: center; /* Center text */
    height: 100%; /* Optionally set height */
    margin-top: 5rem;
    margin-bottom: 5rem;
}

.muay-thai-technique-title {
    margin-bottom: 1rem; /* Adjust spacing as needed */
    margin-top: 1rem;
    color: #ffc314;
    font-size: 2.5em;
    width: 100%;
    background-color: white;
}

.muay-thai-technique-content {
    /* Optionally add styles for content container */
    width: 100%;
    background-color: #D6D4d4;
    padding-bottom: 2rem;
    padding-top: 2rem;
}
