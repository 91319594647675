/* CSS */
.contact-container {
    background-color: #ffc314;
    background-image: url('../assets/tpBgFullLogo.png'); /* Update the path if needed */
    background-size: 250px auto; /* Adjust the size to make the image smaller */
    background-position: bottom right  ; /* Positions the image on the right, centered vertically */
    background-repeat: no-repeat; /* Prevents the image from repeating */
    padding: 20px;
    text-align: center; /* Center text within the container */
}




.contact-container h1 {
    font-size: 2.5rem;
    color: white;
}

.contact-container p {
    font-weight: bold;
}

.contact-buttons-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; /* Display buttons in a column layout */
    margin-top: 20px;
}

.contact-button {
    margin: 10px; /* Adjust margin between buttons */
    position: relative; /* Position container relatively for absolute positioning of icon */
}

.contact-button a {
    display: inline-block; /* Display button as a block */
    padding: 20px 40px;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    font-family: Arial, sans-serif;
    font-size: 16px;
    font-weight: bold;
    transition: background-color 0.3s ease;

}

.contact-button a:hover {
    opacity: 0.8; /* Reduce opacity on hover for visual feedback */
}

.contact-icon {
    position: absolute;
    top: 50%; /* Align icon to the middle vertically */
    transform: translateY(-50%); /* Center icon vertically */
    right: 5px;
    width: 2rem;
    height: 2rem;
}

/* Button-specific styles */
.whatsapp-contact-button {
    background-color: #25D366; /* WhatsApp button color */
}

.whatsapp-contact-button:hover {
    background-color: #128C7E; /* WhatsApp button hover color */
}

.instagram-contact-button {
    background-color: #C13584; /* Instagram button color */
}

.instagram-contact-button:hover {
    background-color: #8A3AB9; /* Instagram button hover color */
}

.facebook-contact-button {
    background-color: #3B5998; /* Facebook button color */
}

.facebook-contact-button:hover {
    background-color: #4267B2; /* Facebook button hover color */
}

.email-contact-button {
    background-color: #ff5733; /* Email button color */
}

.email-contact-button:hover {
    background-color: #e63300; /* Email button hover color */
}

.message-pointer-icon {
    font-size: 1.5rem;
}