.training-schedule {
    margin-top: 5rem;
}

.training-schedule h1 {
    font-size: 2.5em;
    color: #ffc314;
    text-align: center;
}

.training-schedule h2 {
    font-size: 2.1rem;
    color: black;
    text-align: center;
}

.training-schedule h3 {
    font-size: 1.5rem;
    background-color: #ffc314;
    text-align: center;
}

.training-schedule p {
    font-weight: bold;
    text-align: center;
    font-size: 1rem;
}

.training-schedule {
    color: black;
}

.schedule-display {
    display: flex;
    justify-content: space-around;
    background-color: black;
    flex-wrap: wrap; /* Allow flex items to wrap to the next line */
    padding: 20px; /* Add padding instead of margin */
    margin: 20px;
}

.schedule-card {
    position: relative; /* Make it a positioning context for absolute positioning */
    width: 90%; /* Adjust width for mobile */
    max-width: 320px; /* Set max-width */
    border: 1px solid #ddd;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
    margin-top: 30px;
    margin-bottom: 30px;
    background-color: white;
}

.training-schedule img {
    max-width: 100%; /* Make the image responsive */
    height: auto;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 20px; /* Add space between the last card and the image */
}

/* Apply styles for mobile devices */
@media only screen and (max-width: 600px) {
    .schedule-card {
        width: 100%; /* Adjust width for mobile */
    }
}
