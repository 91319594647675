.m-header {
    background-color: black;
    color: #ffc314;
    padding: 20px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    position: fixed; /* Make the header sticky */
    top: 0; /* Stick it to the top of the viewport */
    width: 100%; /* Ensure it spans the entire width */
    z-index: 1000; /* Ensure it's on top of other elements */
}

.m-header h1 {
    font-size: 21px;
    margin: 0; /* Remove margin for h1 */

}

.title-and-menu {
    display: flex;
    align-items: center;

}

.menu-toggle {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    z-index: 2;
    margin-left: 50px;
}

.bar {
    width: 25px;
    height: 3px;
    background-color: #fff;
    margin: 5px 0;
    transition: transform 0.3s ease, background-color 0.3s ease;
}

.menu-toggle.open .bar:first-child {
    transform: rotate(-45deg) translate(-11px, 13px);
}

.menu-toggle.open .bar:nth-child(2) {
    opacity: 0;
}

.menu-toggle.open .bar:last-child {
    transform: rotate(45deg) translate(-5px, -6px);
}

.nav-links {
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: black;
    width: 100%;
    height: 100vh;
    text-align: center;
    transform: translateX(-120%);
    transition: transform 0.3s ease-in-out;
    overflow-y: auto;
    z-index: 1;
}

.nav-links.open {
    transform: translateX(0);
    transform: translateY(40%);
}

.m-header ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
}

.m-header li {
    margin: 10px 0;
}

.m-header a {
    text-decoration: none;
    color: #ffffff;
    font-weight: bold;
    font-size: 17px;
    transition: color 0.3s ease;
}

.m-header a:hover {
    color: #ffc314;
}

@media screen and (min-width: 769px) {
    .m-header {
        flex-direction: row;
        align-items: center;
    }

    .menu-toggle {
        display: none; /* Hide the hamburger menu on desktop */
    }

    .nav-links {
        position: static;
        transform: none;
        flex-direction: row;
        background-color: transparent;
        width: auto;
        height: auto;
        overflow-y: visible;
    }

    .m-header ul {
        flex-direction: row;
    }

    .m-header li {
        margin: 0 15px;
    }

    .m-header a {
        font-size: 16px;
    }
}
