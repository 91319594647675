body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

html {
  overscroll-behavior: none !important;
  overflow-x: hidden;
  width: 100%;
}

/* Apply overscroll-behavior: none; only in Firefox */
@-moz-document url-prefix() {

  /* Apply the CSS property to the desired element */
  body {
    overscroll-behavior: none !important;
  }
}
