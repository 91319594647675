/* Gallery.css */

.Gallery {
    overflow: hidden; /* Ensures that the inner container doesn't overflow */
    margin-top: 5rem;
}

.Image-outer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 500px;

}
.Gallery-inner {
    display: flex; /* Using flexbox to horizontally align images */
    transition: transform 0.3s ease; /* Smooth transition for slide effect */
}

.Gallery-inner > div {
    flex: 0 0 100%; /* Forces each image to take up the full width */
}

.Gallery-inner img {
    width: 100%; /* Ensures images fill their container */
    height: 500px; /* Sets a fixed height */
    object-fit: contain; /* Ensures the entire image fits within the container */
    display: block; /* Removes gaps below the image */
}



/* Media query for desktop screens */
@media screen and (min-width: 768px) {
    .Gallery-inner img {
        max-width: 50%; /* Set maximum width for images on desktop */
    }
}
