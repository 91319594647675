/* Reset some default styles to ensure consistency across browsers */
body, h1, h2, h3, p, ul, li {
  margin: 0;
  padding: 0;
}

/* Apply a box-sizing border-box model to all elements */
* {
  box-sizing: border-box;
}

/* Set default font styles and sizes */
body {
  font-family: 'Arial', sans-serif;
  font-size: 16px;
  line-height: 1.6;
}

/* Apply some basic styling to improve readability */
h1, h2, h3 {
  margin-bottom: 1rem;
}

p {
  margin-bottom: 1.5rem;
}

/* Define some global color variables */
:root {
  --primary-color: #3498db;
  --secondary-color: #2ecc71;
  --text-color: #333;
}

/* Apply global link styles */
a {
  color: var(--primary-color);
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

/* Apply basic layout styles */
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

/* Add responsive styles for smaller screens */
@media screen and (max-width: 768px) {
  body {
    font-size: 14px;
  }
}

/* Add additional global styles as needed */



button {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 3px;
  padding: 10px 15px;
  font-size: 16px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}
