/* Add this to your CSS file or use styled-components */

.trainers-container {
    text-align: center;
    margin: 20px;
}

.trainers-container h1 {
    font-size: 2.5rem;
    color: #ffc314;
}

.trainers-container p {
    font-size: 1.3rem;
    color: black;
    font-weight: bold;
    background-color: white;
    position: absolute;
    bottom: -3rem;
    right: 3rem;
    padding: 30px;
    border-radius: 5px;
    width: 60%;
}

.trainers-display {
    display: flex;
    justify-content: space-around;
    background-color: #D6D4D4CC;
    flex-wrap: wrap; /* Allow flex items to wrap to the next line */
    padding: 20px; /* Add padding instead of margin */
}

.trainer-card {
    position: relative; /* Make it a positioning context for absolute positioning */
    width: 90%; /* Adjust width for mobile */
    max-width: 320px; /* Set max-width */
    border: 1px solid #ddd;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
    margin-top: 30px;
    margin-bottom: 30px;
}

/* Apply styles for mobile devices */
@media only screen and (max-width: 600px) {
    .trainers-display {
        flex-direction: column; /* Change flex direction to column for mobile */
        align-items: center; /* Center align items */
    }
}

.trainers-container img {
    max-width: 100%;
    height: auto;
    border-radius: 5px;
}

.trainer-name {
    margin-top: 10px;
    font-weight: bold;
    color: #444;
}


















/*

.trainers-container {
    text-align: center;
    margin: 20px;
}

.trainers-container h1 {
    font-size: 2em;
    color: #ffc314;
}

.trainers-container p {
    font-size: 1.2em;
    color: black;
    font-weight: bold;
    background-color: white;
}

.trainers-display {
    display: flex;
    justify-content: space-around;
    background-color: #D6D4D4CC;
    flex-wrap: wrap; !* Allow flex items to wrap to the next line *!
}

.trainer-card {
    width: 260px;
    border: 1px solid #ddd;
    padding: 10px;
    margin: 10px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
}

!* Apply styles for mobile devices *!
@media only screen and (max-width: 600px) {
    .trainers-display {
        flex-direction: column; !* Change flex direction to column for mobile *!
        align-items: center; !* Center align items *!
    }
}

.trainers-container img {
    max-width: 100%;
    height: auto;
    border-radius: 5px;
}

.trainer-name {
    margin-top: 10px;
    font-weight: bold;
    color: #444;
}
*/
