/* Add this to your CSS file or use styled-components */

/* Desktop styles */
.outer-prices-container {
    background-color: #D6D4d4;
    padding: 5rem;
    position: relative; /* Position relative to its containing block */
}

.prices-container {
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: flex-start; /* Align items to the start of the container */
    padding: 2rem;
}

.p-align-start {
    flex: 1; /* Take up equal space in the container */
    text-align: start; /* Center align content */
    margin-right: 1rem; /* Add margin between sections */
}

.p-align-center {
    flex: 1; /* Take up equal space in the container */
    text-align: start; /* Center align content */
    margin: 0 1rem; /* Add margin between sections */
}

.p-align-end {
    flex: 1; /* Take up equal space in the container */
    display: flex; /* Use flexbox to center the image */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    position: relative; /* Position relative to its containing block */
}

.training-prices-title {
    background-color: #ffc314;
    color: black;
    font-size: 2em;
    margin-bottom: 1rem; /* Adjust margin bottom as needed */
}

.ten-session-pass-title {
    background-color: #D6D4d4;
    color: black;
    font-size: 2em;
    margin-bottom: 1rem; /* Adjust margin bottom as needed */
}

.prices-container p {
    font-size: 1.3rem;
    color: black;
    margin-bottom: 0rem; /* Adjust margin bottom as needed */
}

.prices-container span {
    font-size: 0.8rem;
    position: absolute; /* Position relative to the container */
    margin-top: -0.7rem;
}

.prices-container strong {
    font-weight: bold;
}

.prices-container img {
    max-width: 100%; /* Make image responsive */
    height: 100%;
}

/* Mobile responsiveness */
@media only screen and (max-width: 600px) {
    .outer-prices-container {
        padding: 2rem; /* Adjust padding for mobile */
    }

    .prices-container {
        flex-direction: column; /* Stack items vertically on mobile */
        align-items: center; /* Center items horizontally */
    }

    .p-align-start,
    .p-align-center,
    .p-align-end {
        width: 100%; /* Full width on mobile */
        margin-bottom: 2rem; /* Add space between sections */
    }

    .p-align-start,
    .p-align-center {
        margin-right: 0; /* Remove margin on mobile */
    }

    .p-align-end {
        margin-left: 0; /* Remove margin on mobile */
    }

    .prices-container img {
        max-width: 80%; /* Adjust image width for mobile */
    }
}

