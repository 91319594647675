/* Footer styles */
footer {
    background-color: #222222; /* New background color */
    color: #ffc314;
    padding: 20px;
    text-align: center;
    bottom: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.footer-logo img {
    width: 50px;
    margin-bottom: 10px;
    border-radius: 10px;
}

.footer-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.footer-links {
    margin-top: 10px;
}

.footer-links a {
    color: #ffc314;
    margin: 0 10px;
    text-decoration: none;
    font-size: 10px;
}

.footer-contact {
    margin-top: 20px;
}

.footer-contact p {
    margin: 5px 0;
}

.footer-social {
    margin-top: 20px;
}

.footer-social a {
    margin: 0 10px;
}

.footer-social img {
    width: 30px;
}
