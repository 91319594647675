.contact-social {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contact-social-redirect {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contact-social-redirect-title p {
    margin: 10px 0;
    font-size: 2.5rem;
    background-color: #ffc314;
    color: black;
    font-weight: bold;
    text-align: center;
}

.contact-social-align-row img {
    width: 50px; /* Adjust size as needed */
    height: 50px; /* Adjust size as needed */
    display: flex;
    align-items: center;
    margin: 15px;
}
.contact-social-align-row {
    display: flex;
    flex-direction: row;
}

.contact-social-align-row a {
    text-decoration: none;
    color: inherit;
}

.contact-social-align-row a:hover {
    opacity: 0.8;
}
