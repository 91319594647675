/* Classes.css */

.classes-container {
    display: flex;
    flex-direction: column; /* Change to a column layout for small screens */
}

.align-start-column, .align-end-column {
    width: 100%; /* Take full width on small screens */
    text-align: center; /* Center text on small screens */
}


.align-start-column {
    background-color: black;
}
.align-start-column h1 {
    color: black;
    font-weight: bolder;
    margin-top: 10px;
    font-size: 2.5rem;
}
.align-start-column h3 {
    display: inline-block; /* or inline; Remove? */
    width: 100%;
    font-size: 20px;
    font-weight: bold;
}
.align-start-column p {
    font-size: 20px;
    font-weight: bold;
}

.class-schedule {
    padding-top: .75rem;
    padding-bottom: .75rem;
}
.gym-image {
    width: 100%;
    max-width: 100%; /* Make the image responsive */
    height: 100%;
}

.classes-bg-container {
    background-color: black;
}
.class-schedule {
    font-family: Arial, sans-serif;
    margin: 0 auto;
    max-width: 600px;
    padding: 20px;
}

.class-card-container {
    border-radius: 5px;
    padding: 20px;
}

.personal-training-times {
    font-family: Arial, sans-serif;
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #ffd700; /* yellow background */
    border-radius: 10px;
}

.class-card-title {
    background-color: black;
    font-size: 24px;
    color: white; /* black title */
    margin-bottom: 10px;
}

.class-card-subtitle {
    font-weight: bold;
    color: #ffd700; /* black subtitle */
    margin-bottom: 5px;
}
.class-card-privates-subtitle {
    font-weight: bold;
    color: black;
    margin-top: -1rem;
}
.align-flex-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

}

.personal-training-times {
    border: 1rem solid black;
    border-bottom: none;
    border-radius: 0px;
    margin-left: 1rem;
    margin-right: 1rem;
    margin-top: -2rem;
    padding-bottom: 2rem;
}

.class-time {
    background: black;
    color: #ffd700; /* dark gray time */
    margin-bottom: 0px;
    margin-top: -.5rem;
}

.class-cta {
    font-weight: bold;
    text-decoration: underline;
    margin-top: -0.2rem;

}


@media (min-width: 768px) {
    /* Apply styles for screens larger than 768px (tablets and desktops) */
    .classes-container {
        flex-direction: row; /* Switch back to a row layout */
        padding: 100px;
    }

    .align-start-column, .align-end-column {
        width: 50%; /* Take half of the width on larger screens */
        text-align: center;
    }
}
